<template>
  <v-layout v-resize="onResize" column>
    <div>
      <template v-if="!$route.meta.allowAnonymous">
        <v-app
          v-if="this.isMobile == false"
          :style="{
            backgroundImage: 'url(' + require('@/assets/bg.png') + ')',
          }"
        >
          <navbar />
          <v-main>
            <router-view></router-view>
          </v-main> </v-app
        ><v-app
          v-if="this.isMobile == true"
          :style="{
            backgroundImage: 'url(' + require('@/assets/bg.png') + ')',
          }"
        >
          <v-main>
            <h2
              class="white--text"
              style="
                position: absolute;
                top: 50vh;
                left: 22vw;
                z-index: 2;
                overflow: hidden;
              "
            >
              Please rotate your device!
            </h2>
          </v-main>
        </v-app>
      </template>
      <template v-else>
        <v-app>
          <transition>
            <keep-alive>
              <router-view></router-view>
            </keep-alive>
          </transition>
        </v-app>
      </template>
    </div>
  </v-layout>
</template>

<script>
import navbar from "./views/navbar.vue";
export default {
  name: "App",
  components: {
    navbar,
  },
  data: () => ({
    image: "bg.png",
    isMobile: false,
    isApp: false,
  }),
  mounted() {
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      //console.log("webapp is installed");
      this.isApp = true;
    }
  },
  methods: {
    onResize() {
      if (window.innerWidth < window.innerHeight) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Teko");
@import url("https://fonts.googleapis.com/css?family=Prompt");

.v-application {
  font-family: "Teko";
  //background-image: url("@/assets/bg.png");
}
.v-btn__content {
  font-size: 2.3vh;
}
</style>
