import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "trains/";
import authHeader from "../services/auth-header";
export const trains = {
  state: {
    trains: [],
  },
  getters: {
    alltrain: (state) => state.trains,
  },
  mutations: {
    setTrains(state, trains) {
      state.trains = trains;
    },
    setStart(state, train) {
      state.trains[train.No].Start = train.Start;
    },
    setSpeed(state, train) {
      state.trains[train.No].Speed = train.Speed;
    },
    setEnable(state, train) {
      state.trains[train.No].selectStation[train.index].Enable = train.Enable;
    },
    setClear(state, train) {
      state.trains[train.No].Start = train.Start;
      state.trains[train.No].Speed = train.Speed;
      state.trains[train.No].selectStation = train.selectStation;
    },

    setMode(state, device) {
      state.trains[0].Device[device.Number].Auto = device.Auto;
    },
    setOnOff(state, device) {
      state.trains[0].Device[device.Number].On = device.On;
    },
    setAutoAll(state) {
      for (let i = 0; i < state.trains[0].Device.length; i++) {
        state.trains[0].Device[i].Auto = true;
      }
    },
    setEmer(state, data) {
      state.trains[0].Emergency = data.Emergency
    },
    SOCKET_STATUS(state, payload) {
      //console.log(payload);
      const status = JSON.parse(payload.status);
      console.log(status);
      const index = state.trains.findIndex((s) => s.name === payload.name);
      function cheackStation(num) {
        switch (num) {
          case 1:
            return "TGGS";
          case 2:
            return "STRI";
          case 3:
            return "Thai-French";
          case 4:
            return "FTE";
          case 5:
            return "CIT";
          case 6:
            return "Science";
          case 7:
            return "Engineering";
          case 8:
            return "Soccer Field";
          case 9:
            return "Techno park";
        }
      }
      if (index > -1 && status.Station.length != 0 && status.Video != '') {
        state.trains[index].Station = status.Station;
        state.trains[index].Event = status.Event;
        state.trains[index].Passengers = status.Passengers;
        state.trains[index].Video.TimeCount = status.Video.TimeCount;
        state.trains[index].Video.speed = status.Video.speed;
        state.trains[index].DeviceAuto = status.DeviceAuto;
        state.trains[index].DeviceOutput = status.DeviceOutput;
        state.trains[index].Video.nowStation = cheackStation(
          status.Video.nowStation
        );
        state.trains[index].Video.nextStation = cheackStation(
          status.Video.nextStation
        );
      }
    },
  },
  actions: {
    async getTrains(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("setTrains", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    testMotor({ commit }, payload) {
      return axios
        .put(apiURL + "testMotor/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          //commit("testMotor", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setSpeed({ commit }, payload) {
      return axios
        .put(apiURL + "set/speed/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setSpeed", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setClear({ commit }, payload) {
      let data = payload;
      data.Clear = true;
      return axios
        .put(apiURL + "set/clear/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setClear", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setStart({ commit }, payload) {
      return axios
        .put(apiURL + "set/start/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setStart", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setEnable({ commit }, payload) {
      return axios
        .put(apiURL + "set/enable/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setEnable", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setMode({ commit }, payload) {
      return axios
        .put(apiURL + "set/device/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setMode", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setOnOff({ commit }, payload) {
      return axios
        .put(apiURL + "set/device/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setOnOff", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setAutoAll({ commit }, payload) {
      return axios
        .put(apiURL + "set/device/autoAll/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setAutoAll", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setEmer({ commit }, payload) {
      console.log(payload)
      return axios
        .put(apiURL + "set/emer/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setEmer", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
  },
};
