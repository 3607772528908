import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";

import { trains } from "./trains.module";
import { accounts } from "./accounts.module";
import { operation } from "./operation.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    trains,
    accounts,
    operation
  },
});
