<template>
  <div v-if="getTrain[0]">
    <v-row align="center" justify="center" style="height: 50vh">
      <v-col cols="3">
        <p
          class="white--text text-center pa-0 mb-n5 ml-n10"
          style="font-size: 4vh"
        >
          STATION 1
        </p>
        <v-img width="20vw" :src="require('@/assets/station.png')"></v-img>
      </v-col>
      <v-col cols="6">
        <v-img
          width="15vw"
          style="position: absolute; top: 18vh; z-index: 3; overflow: hidden"
          :style="position()"
          :src="require('@/assets/train.png')"
        ></v-img>
      </v-col>
      <v-col cols="3">
        <p
          class="white--text text-center pa-0 mb-n5 ml-n10"
          style="font-size: 4vh"
        >
          STATION 2
        </p>
        <v-img width="20vw" :src="require('@/assets/station.png')"></v-img>
      </v-col>
    </v-row>
    <svg
      style="
        position: absolute;
        top: 30vh;
        left: 15vw;
        z-index: 2;
        overflow: hidden;
        width: 3vw;
        height: 3vw;
      "
    >
      <circle
        cx="1.5vw"
        cy="1.5vw"
        r="1vw"
        stroke-width="3"
        :fill="statusStation(1)"
      />
    </svg>
    <svg
      style="
        position: absolute;
        top: 30vh;
        left: 35vw;
        z-index: 2;
        overflow: hidden;
        width: 3vw;
        height: 3vw;
      "
    >
      <circle
        cx="1.5vw"
        cy="1.5vw"
        r="1vw"
        stroke-width="3"
        :fill="statusStation(2)"
      />
    </svg>
    <svg
      style="
        position: absolute;
        top: 30vh;
        left: 55vw;
        z-index: 2;
        overflow: hidden;
        width: 3vw;
        height: 3vw;
      "
    >
      <circle
        cx="1.5vw"
        cy="1.5vw"
        r="1vw"
        stroke-width="3"
        :fill="statusStation(3)"
      />
    </svg>
    <svg
      style="
        position: absolute;
        top: 30vh;
        left: 75vw;
        z-index: 2;
        width: 3vw;
        height: 3vw;
      "
    >
      <circle
        cx="1.5vw"
        cy="1.5vw"
        r="1vw"
        stroke-width="3"
        :fill="statusStation(1)"
      />
    </svg>
    <v-img
      width="35vw"
      style="position: absolute; top: 32vh; left: 15vw; z-index: 1"
      :src="require('@/assets/rail2.svg')"
    ></v-img>
    <v-img
      width="35vw"
      style="
        position: absolute;
        top: 32vh;
        right: 18vw;
        z-index: 1;
        overflow: hidden;
      "
      :src="require('@/assets/rail.svg')"
    ></v-img>

    <v-row
      justify="start"
      class="mt-1"
      style="height: 20vh"
      v-if="getAccess != undefined"
    >
      <v-col cols="2" v-if="getTrain[0].Emergency == false">
        <div @click="emergency()">
          <v-img
            class="st5"
            justify="center"
            width="30vh"
            :class="getAccess == undefined ? 'disabled' : ''"
            :src="require('@/assets/red-btn.svg')"
          ></v-img>
        </div>
      </v-col>
      <v-col cols="2" v-else>
        <div @click="clearEmer()">
          <v-img
            class="st5"
            justify="center"
            width="30vh"
            :class="getAccess == undefined ? 'disabled' : ''"
            :src="require('@/assets/yellow-btn.svg')"
          ></v-img>
        </div>
      </v-col>

      <v-col
        cols="3"
        style="
          width: 10vw;
          height: 35vh;
          border: 4px solid #5e90cc;
          border-radius: 0.5vh;
          font-size: 2.7vh;
        "
        class="text-center"
      >
        <h2 class="white--text text-center">Device</h2>
        <v-btn
          outlined
          style="height: 5vh"
          :style="cheackMode(getTrain[0].DeviceAuto[7])"
          :color="getTrain[0].DeviceOutput[7] ? 'success' : 'error'"
          :class="getAccess == undefined ? 'disabled' : ''"
          class="bg control"
          @click="openDialog(getTrain[0].Device[7])"
        >
          Motor
        </v-btn>
        <v-btn
          outlined
          :style="cheackMode(getTrain[0].DeviceAuto[5])"
          :color="getTrain[0].DeviceOutput[5] ? 'success' : 'error'"
          style="height: 5vh"
          class="bg my-1 door"
          :class="getAccess == undefined ? 'disabled' : ''"
          @click="openDialog(getTrain[0].Device[5])"
        >
          Door 1
        </v-btn>
        <v-btn
          outlined
          style="height: 5vh"
          :style="cheackMode(getTrain[0].DeviceAuto[6])"
          :color="getTrain[0].DeviceOutput[6] ? 'success' : 'error'"
          :class="getAccess == undefined ? 'disabled' : ''"
          class="bg my-1 door"
          @click="openDialog(getTrain[0].Device[6])"
        >
          Door 2
        </v-btn>
      </v-col>
      <v-col cols="2"></v-col>
      <v-col
        cols="3"
        style="
          width: 10vw;
          height: 35vh;
          border: 4px solid #5e90cc;
          border-radius: 0.5vh;
          font-size: 2.7vh;
        "
      >
        <h2 class="white--text text-center">Program Train</h2>
        <v-row justify="space-around" class="mt-5">
          <v-btn
            style="height: 5vh; width: 5vw"
            :dark="controlTrain.auto ? true : false"
            :color="controlTrain.auto ? 'amber darken-3' : 'white'"
            @click="setModeTrain(true)"
          >
            Auto
          </v-btn>
          <v-btn
            style="height: 5vh; width: 5vw"
            :dark="!controlTrain.auto ? true : false"
            :color="!controlTrain.auto ? 'light-blue darken-1' : 'white'"
            @click="setModeTrain(false)"
          >
            manual
          </v-btn>
        </v-row>
        <v-row justify="space-around" v-if="this.controlTrain.auto == true">
          <v-col cols="12">
            <v-text-field
              style="height: 5vh; margin: 0; padding: 0"
              dark
              v-model="controlTrain.round"
              hide-details
              outlined
              dense
              type="number"
              min="0"
              step="1"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="text-start">
            <v-btn
              style="height: 5vh; width: 5vw"
              :color="controlTrain.start ? 'success' : 'white'"
              :disabled="controlTrain.start ? true : false"
              width="100px"
              @click="setTrainAuto(true)"
            >
              start
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              style="height: 5vh; width: 5vw"
              :color="!controlTrain.start ? 'error' : 'white'"
              :disabled="controlTrain.start ? true : false"
              width="100px"
              @click="setTrainAuto(false)"
            >
              stop
            </v-btn>
          </v-col>
        </v-row>
        <v-row  v-else class="mt-5">
           <v-col cols="6" class="text-start">
            <v-btn
              :color="motor.On ? 'success' : 'white'"
              :disabled="motor.Auto ? true : false"
              style="height: 5vh; width: 5vw"
              @click="setOnOff(true)"
            >
              ON
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              :color="!motor.On ? 'error' : 'white'"
              :disabled="motor.Auto ? true : false"
              style="height: 5vh; width: 5vw"
              @click="setOnOff(false)"
            >
              OFF
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-staet">
            <v-btn
              style="height: 5vh; width: 5vw"
              :color="controlTrain.revert ? 'success' : 'white'"
              @mousedown="trainRevert(true)"
              @mouseup="trainRevert(false)"
              @touchstart="trainRevert(true)"
              @touchend="trainRevert(false)"
            >
              revert
            </v-btn>
         
          </v-col> 
          <v-col cols="6" class="text-right">
             <v-btn
              style="height: 5vh; width: 5vw"
              :color="controlTrain.forward ? 'success' : 'white'"
              @mousedown="trainForward(true)"
              @mouseup="trainForward(false)"
              @touchstart="trainForward(true)"
              @touchend="trainForward(false)"
            >
              forward
            </v-btn>
          </v-col> 
        </v-row>
      </v-col>
    </v-row>
    <v-dialog max-width="300" v-model="dialog">
      <v-card color="#000">
        <div class="text-center mt-2 white--text" style="font-size: 5vh">
          {{ this.device.Name }}
        </div>
        <v-spacer></v-spacer>
        <v-row align="center" class="mx-2" v-if="device.Number == 7">
          <v-col
            cols="2"
            style="font-size: 4vh"
            class="white--text ml-2 mr-0 pa-0"
          >
            Test:
          </v-col>
          <v-col cols="3" align="center" class="ma-0 pa-0 text-center">
            <v-text-field
              dark
              v-model="voltMotor"
              @change="setFloat"
              placeholder="Volt"
              hide-details
              outlined
              dense
              type="number"
              min="0"
              step="0.1"
            ></v-text-field>
          </v-col>
          <v-col
            cols="3"
            style="font-size: 4vh"
            class="white--text text-center"
          >
            Volt
          </v-col>
          <v-col cols="3" style="font-size: 150%" align="end">
            <v-btn
              dense
              style="width: 20%; height: 30px"
              color="success"
              @click="testMotor()"
            >
              TEST</v-btn
            >
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn
            width="270px"
            @click="setAutoAll()"
            :dark="autoAll ? true : false"
            :color="autoAll ? 'deep-orange' : 'white'"
          >
            Auto All
          </v-btn>
        </v-card-actions>
        <v-card-actions class="my-3">
          <v-btn
            width="100px"
            @click="setMode(true)"
            :dark="device.Auto ? true : false"
            :color="device.Auto ? 'amber darken-3' : 'white'"
          >
            Auto
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            width="100px"
            :dark="!device.Auto ? true : false"
            :color="!device.Auto ? 'light-blue darken-1' : 'white'"
            @click="setMode(false)"
          >
            manual
          </v-btn>
        </v-card-actions>
        <v-card-actions
          class="my-3"
          v-if="device.Number == 5 || device.Number == 6"
        >
          <v-btn
            :color="device.On ? 'success' : 'white'"
            :disabled="device.Auto ? true : false"
            width="100px"
            @click="setOnOff(true)"
          >
            open
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="!device.On ? 'error' : 'white'"
            :disabled="device.Auto ? true : false"
            width="100px"
            @click="setOnOff(false)"
          >
            close
          </v-btn>
        </v-card-actions>
        <v-card-actions class="my-3" v-else>
          <v-btn
            :color="device.On ? 'success' : 'white'"
            :disabled="device.Auto ? true : false"
            width="100px"
            @click="setOnOff(true)"
          >
            ON
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="!device.On ? 'error' : 'white'"
            :disabled="device.Auto ? true : false"
            width="100px"
            @click="setOnOff(false)"
          >
            OFF
          </v-btn>
        </v-card-actions>
        <v-card-actions class="my-3" v-if="device.Number == 7 && device.On">
          <v-btn
            width="100px"
            :color="controlTrain.revert ? 'success' : 'white'"
            @mousedown="trainRevert(true)"
            @mouseup="trainRevert(false)"
            @touchstart="trainRevert(true)"
            @touchend="trainRevert(false)"
          >
            revert
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            width="100px"
            :color="controlTrain.forward ? 'success' : 'white'"
            @mousedown="trainForward(true)"
            @mouseup="trainForward(false)"
            @touchstart="trainForward(true)"
            @touchend="trainForward(false)"
          >
            forward
          </v-btn>
        </v-card-actions>
        <v-card-actions class="my-3">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "operation",

  data() {
    return {
      scale: 2,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      voltMotor: 0.0,
      autoAll: false,
      device: {
        _id: null,
        Number: null,
        Name: null,
        On: false,
        Auto: false,
      },
      motor: {
        _id: null,
        Number: null,
        Name: null,
        On: false,
        Auto: false,
      },
      controlTrain: {
        auto: false,
        round: 0,
        start: false,
        forward: false,
        revert: false,
      },
      dialog: false,
      auto: false,
      manual: false,
      on: false,
      off: false,
    };
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    getTrain() {
      return this.$store.getters.alltrain;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.$store.dispatch("getTrains");
     this.device._id = this.getTrain[0]._id;
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    trainRevert(data) {
      this.controlTrain.revert = data;
      let payload = {
        _id: this.getTrain[0]._id,
        revert: data,
      };
      this.$store.dispatch("MotorRevert", payload);
    },
    trainForward(data) {
      this.controlTrain.forward = data;
      let payload = {
        _id: this.getTrain[0]._id,
        forward: data,
      };
      console.log(payload);
      this.$store.dispatch("MotorForward", payload);
    },
    setFloat() {
      let int = this.voltMotor;
      let flote = parseFloat(int).toFixed(1);
      this.voltMotor = flote;
    },
    onResize() {
      this.width = document.documentElement.clientWidth - 100;
      this.height = document.documentElement.clientHeight;
    },
    emergency() {
      if (this.getAccess != undefined) {
        const id = this.getTrain[0]._id;
        let confirmFn = function () {
          try {
            let payload = {
              _id: id,
              Emergency: true,
            };
            this.$store.dispatch("setEmer", payload);
          } catch (error) {
            console.log(error);
          }
        };
        let obj = {
          message:
            '<span style="font-size: 180%; font-weight: bold;">You want to set emergency?</span>',
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "  Yes  ",
          customCloseBtnText: "Cancel",
          customConfirmBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default primary mx-4 same",
          customCloseBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default error same",
          onConfirm: confirmFn,
        };
        this.$Simplert.open(obj);
      }
    },
    clearEmer() {
      if (this.getAccess != undefined) {
        const id = this.getTrain[0]._id;
        let confirmFn = function () {
          try {
            let payload = {
              _id: id,
              Emergency: false,
            };
            this.$store.dispatch("setEmer", payload);
          } catch (error) {
            console.log(error);
          }
        };
        let obj = {
          message:
            '<span style="font-size: 180%; font-weight: bold;">You want to set clear emergency?</span>',
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "  Yes  ",
          customCloseBtnText: "Cancel",
          customConfirmBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default primary mx-4 same",
          customCloseBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default error same",
          onConfirm: confirmFn,
        };
        this.$Simplert.open(obj);
      }
    },
    position() {
      return "left: 45vw;";
    },
    openDialog(data) {
      if (this.getAccess.role == "Admin") {
        this.device.Name = data.Name;
        this.device.Number = data.Number;
        this.device.On = data.On;
        this.device.Auto = data.Auto;
        this.dialog = true;
      }
    },
    setModeTrain(data) {
      this.controlTrain.auto = data;
    },
    setMode(data) {
      console.log(this.device.Number);
      this.device.Auto = data;
      this.$store.dispatch("setMode", this.device).then(() => {
        this.autoAll = this.cheackAuto;
      });
    },
    setAutoAll() {
      this.device.Auto = true;
      this.$store.dispatch("setAutoAll", this.device).then(() => {
        this.autoAll = this.cheackAuto;
      });
    },
    setOnOff(data) {
      console.log(this.device.Number);
      if(this.device.Number == null){
        this.device.Number = 7
      }
      this.device.On = data;
      this.$store.dispatch("setOnOff", this.device);
    },
    statusStation(data) {
      if (data == 1) {
        return "green";
      } else if (data == 2) {
        return "yellow";
      } else {
        return "red";
      }
    },
    cheackMode(data) {
      //console.log(this.autoAll);
      if (this.autoAll == true) {
        return "border-color: #ff5722;";
      } else if (data == true) {
        return "border-color: #FFB300;";
      } else {
        return "border-color: #039BE5;";
      }
    },
  },
};
</script>
<style type="text/css"></style>
