<template>
  <div class="ma-0 pa-0">
    <v-btn
      outlined
      color="#d19208"
      style="position: absolute; left: 1%; top: 2%; z-index: 1"
      @click="play()"
      >reset</v-btn
    >
    <vue-plyr ref="plyr" :options="options" style="z-index: -1">
      <video width="500">
        <source src="/video/back.mp4" type="video/mp4" />
      </video>
    </vue-plyr>
    <v-col
      style="position: absolute; left: 47%; top: 70%; z-index: 1"
      class="ma-0 pa-0"
    >
      <div class="white--text textxl">timer s</div>
    </v-col>
    <v-col
      style="position: absolute; left: 20%; top: 90%; z-index: 1"
      class="mb-n1 pa-0"
    >
      <div class="white--text textxl">Station</div>
    </v-col>
    <v-col
      style="position: absolute; left: 45%; top: 90%; z-index: 1"
      class="mb-n1 pa-0"
    >
      <div class="white--text textxl">
        Speed {{ this.getTrain[0].Speed }}km/hr
      </div>
    </v-col>
    <v-col
      style="position: absolute; left: 80%; top: 90%; z-index: 1"
      class="mb-n1 pa-0"
    >
      <div class="white--text textxl">Station</div>
    </v-col>
  </div>
</template>

<script>
// @ is an alias to /src
import Cookies from "js-cookie";
export default {
  name: "Video",
  data() {
    return {
      No: 0,
      options: {
        clickToPlay: false,
        keyboard: { focused: false, global: false },
        fullscreen: {
          enabled: false,
          fallback: false,
          iosNative: false,
          container: null,
        },
        controls: [],
        settings: [],
      },
    };
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    getTrain() {
      return this.$store.getters.alltrain;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    if (this.$route.params.data != null) {
      this.No = this.$route.params.data;
      Cookies.set("noTrain", this.No);
      this.$store.dispatch("getTrains", this.id);
    } else {
      this.No = Cookies.get("noTrain");
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
  },
  methods: {
    reset() {
      this.$refs.plyr.player.stop();
    },
    speed() {
      this.$refs.plyr.player.speed = 3;
    },
  },
};
</script>
<style>
.textxl {
  font-size: 300%;
  letter-spacing: 3px;
}
</style>
