<template>
  <div>
    <v-row>
      <v-col>
        <stream1 />
      </v-col>
      <v-col>
        <stream2 />
      </v-col>
       <v-col>
        <stream3 />
      </v-col>
      <v-col>
        <stream4 />
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import stream1 from "@/components/stream1.vue";
import stream2 from "@/components/stream2.vue";
import stream3 from "@/components/stream3.vue";
import stream4 from "@/components/stream4.vue";
export default {
  components: {
    stream1,
    stream2,
    stream3,
    stream4
  },
  name: "Webcam",
  data() {
    return {
      canvas: null,
    };
  },
};
</script>
