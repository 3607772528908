<template>
  <div v-if="getTrain[0]">
    <v-row justify="center" align="center" class="my-5">
      <!-- <span class="white--text" style="font-size: 5vh"
        >Number Of Passengers :
      </span>
      <span
        class="white--text text-center ml-2"
        style="
          border: 2px solid #d19208;
          border-radius: 5px;
          font-size: 5vh;
          width: 10vw;
          height: 8vh;
        "
        >{{ getTrain[0].Passengers }}</span
      > -->
    </v-row>
    <v-row justify="center" align="start" class="mt-10">
      <v-col cols="2" class="ml-5">
        <v-btn
          :class="getAccess == undefined ? 'disabled' : ''"
          outlined
          class="bg mb-4 control"
          style="height: 5vh"
          :style="cheackMode(getTrain[0].DeviceAuto[1])"
          :color="getTrain[0].DeviceOutput[1] ? 'success' : 'error'"
          @click="openDialog(getTrain[0].Device[1])"
        >
          Light Outside
        </v-btn>
        <v-btn
          :class="getAccess == undefined ? 'disabled' : ''"
          outlined
          :color="getTrain[0].DeviceOutput[2] ? 'success' : 'error'"
          class="bg mb-4 control"
          style="height: 5vh"
          :style="cheackMode(getTrain[0].DeviceAuto[2])"
          @click="openDialog(getTrain[0].Device[2])"
        >
          Light Inside
        </v-btn>
        <v-btn
          :class="getAccess == undefined ? 'disabled' : ''"
          outlined
          :color="getTrain[0].DeviceOutput[3] ? 'success' : 'error'"
          class="bg mb-4 control"
          style="height: 5vh"
          :style="cheackMode(getTrain[0].DeviceAuto[3])"
          @click="openDialog(getTrain[0].Device[3])"
        >
          TV
        </v-btn>
        <v-btn
          :class="getAccess == undefined ? 'disabled' : ''"
          outlined
          style="height: 5vh"
          :style="cheackMode(getTrain[0].DeviceAuto[4])"
          :color="getTrain[0].DeviceOutput[4] ? 'success' : 'error'"
          class="bg control"
          @click="openDialog(getTrain[0].Device[4])"
        >
          Sensor
        </v-btn>
      </v-col>
      <v-col justify="center" align-self="center">
        <v-row justify="center">
          <v-btn
            outlined
            :style="cheackMode(getTrain[0].DeviceAuto[5])"
            :color="getTrain[0].DeviceOutput[5] ? 'success' : 'error'"
            style="height: 5vh"
            class="bg my-1 door"
            :class="getAccess == undefined ? 'disabled' : ''"
            @click="openDialog(getTrain[0].Device[5])"
          >
            Door 1
          </v-btn>
        </v-row>
        <v-img
          class="my-0"
          width="55vw"
          contain
          :src="require('@/assets/APM_Station.png')"
        ></v-img>
        <v-row justify="center" class="mt-2">
          <v-btn
            outlined
            style="height: 5vh"
            :style="cheackMode(getTrain[0].DeviceAuto[6])"
            :color="getTrain[0].DeviceOutput[6] ? 'success' : 'error'"
            :class="getAccess == undefined ? 'disabled' : ''"
            class="bg my-1 door"
            @click="openDialog(getTrain[0].Device[6])"
          >
            Door 2
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="2" class="ml-5">
        <v-btn
          outlined
          style="height: 5vh"
          :style="cheackMode(getTrain[0].DeviceAuto[7])"
          :color="getTrain[0].DeviceOutput[7] ? 'success' : 'error'"
          :class="getAccess == undefined ? 'disabled' : ''"
          class="bg mb-4 control"
          @click="openDialog(getTrain[0].Device[7])"
        >
          Motor
        </v-btn>
        <v-btn
          outlined
          style="height: 5vh"
          :style="cheackMode(getTrain[0].DeviceAuto[8])"
          :color="getTrain[0].DeviceOutput[8] ? 'success' : 'error'"
          :class="getAccess == undefined ? 'disabled' : ''"
          class="bg mb-4 control"
          @click="openDialog(getTrain[0].Device[8])"
        >
          AC 1
        </v-btn>
        <v-btn
          outlined
          style="height: 5vh"
          :style="cheackMode(getTrain[0].DeviceAuto[9])"
          :color="getTrain[0].DeviceOutput[9] ? 'success' : 'error'"
          :class="getAccess == undefined ? 'disabled' : ''"
          class="bg mb-4 control"
          @click="openDialog(getTrain[0].Device[9])"
        >
          AC 2
        </v-btn>
        <v-btn
          outlined
          style="height: 5vh"
          :style="cheackMode(getTrain[0].DeviceAuto[10])"
          :color="getTrain[0].DeviceOutput[10] ? 'success' : 'error'"
          :class="getAccess == undefined ? 'disabled' : ''"
          class="bg control"
          @click="openDialog(getTrain[0].Device[10])"
        >
          Camara
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      class="ma-0 pa-0"
      style="position: static; left: 3.3%; top: 30vh; z-index: 1"
      v-if="getAccess != undefined"
    >
      <v-col class="mt-n5" cols="2" v-if="getTrain[0].Emergency == false">
        <div @click="emergency()">
          <v-img
            class="st5"
            justify="center"
            width="30vh"
            :class="getAccess == undefined ? 'disabled' : ''"
            :src="require('@/assets/red-btn.svg')"
          ></v-img>
        </div>
      </v-col>
      <v-col class="mt-n5" cols="2" v-else>
        <div @click="clearEmer()">
          <v-img
            class="st5"
            justify="center"
            width="30vh"
            :class="getAccess == undefined ? 'disabled' : ''"
            :src="require('@/assets/yellow-btn.svg')"
          ></v-img>
        </div>
      </v-col>
    </v-row>
    <v-dialog max-width="300" v-model="dialog">
      <v-card color="#000">
        <div class="text-center mt-2 white--text" style="font-size: 5vh">
          {{ this.device.Name }}
        </div>
        <v-spacer></v-spacer>
        <v-row align="center" class="mx-2" v-if="device.Number == 7">
          <v-col
            cols="2"
            style="font-size: 4vh"
            class="white--text ml-2 mr-0 pa-0"
          >
            Test:
          </v-col>
          <v-col cols="3" align="center" class="ma-0 pa-0 text-center">
            <v-text-field
              dark
              v-model="voltMotor"
              @change="setFloat"
              placeholder="Volt"
              hide-details
              outlined
              dense
              type="number"
              min="0"
              step="0.1"
            ></v-text-field>
          </v-col>
          <v-col
            cols="3"
            style="font-size: 4vh"
            class="white--text text-center"
          >
            Volt
          </v-col>
          <v-col cols="3" style="font-size: 150%" align="end">
            <v-btn
              dense
              style="width: 20%; height: 30px"
              color="success"
              @click="testMotor()"
            >
              TEST</v-btn
            >
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn
            width="270px"
            @click="setAutoAll()"
            :dark="autoAll ? true : false"
            :color="autoAll ? 'deep-orange' : 'white'"
          >
            Auto All
          </v-btn>
        </v-card-actions>
        <v-card-actions class="my-3">
          <v-btn
            width="100px"
            @click="setMode(true)"
            :dark="device.Auto ? true : false"
            :color="device.Auto ? 'amber darken-3' : 'white'"
          >
            Auto
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            width="100px"
            :dark="!device.Auto ? true : false"
            :color="!device.Auto ? 'light-blue darken-1' : 'white'"
            @click="setMode(false)"
          >
            manual
          </v-btn>
        </v-card-actions>
        <v-card-actions
          class="my-3"
          v-if="device.Number == 5 || device.Number == 6"
        >
          <v-btn
            :color="device.On ? 'success' : 'white'"
            :disabled="device.Auto ? true : false"
            width="100px"
            @click="setOnOff(true)"
          >
            open
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="!device.On ? 'error' : 'white'"
            :disabled="device.Auto ? true : false"
            width="100px"
            @click="setOnOff(false)"
          >
            close
          </v-btn>
        </v-card-actions>
        <v-card-actions class="my-3" v-else>
          <v-btn
            :color="device.On ? 'success' : 'white'"
            :disabled="device.Auto ? true : false"
            width="100px"
            @click="setOnOff(true)"
          >
            ON
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="!device.On ? 'error' : 'white'"
            :disabled="device.Auto ? true : false"
            width="100px"
            @click="setOnOff(false)"
          >
            OFF
          </v-btn>
        </v-card-actions>
        <v-card-actions class="my-3" v-if="device.Number == 7 && device.On">
          <v-btn
            width="100px"
            :color="controlTrain.revert ? 'success' : 'white'"
            @mousedown="trainRevert(true)"
            @mouseup="trainRevert(false)"
            @touchstart="trainRevert(true)"
            @touchend="trainRevert(false)"
          >
            revert
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            width="100px"
            :color="controlTrain.forward ? 'success' : 'white'"
            @mousedown="trainForward(true)"
            @mouseup="trainForward(false)"
            @touchstart="trainForward(true)"
            @touchend="trainForward(false)"
          >
            forward
          </v-btn>
        </v-card-actions>
        <v-card-actions class="my-3">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "APM",
  data() {
    return {
      voltMotor: 0.0,
      autoAll: false,
      controlTrain: {
        _id: null,
        auto: false,
        round: 0,
        start: false,
        forward: false,
        revert: false,
      },
      device: {
        _id: null,
        Number: null,
        Name: null,
        On: false,
        Auto: false,
      },
      dialog: false,
      auto: false,
      manual: false,
      on: false,
      off: false,
    };
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    getTrain() {
      return this.$store.getters.alltrain;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    cheackAuto() {
      let device = this.getTrain[0].Device;
      let index = device.findIndex((x) => x.Auto === false);
      if (index > -1) {
        return false;
      } else {
        return true;
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("getTrains");
    this.device._id = this.getTrain[0]._id;
    this.autoAll = this.cheackAuto;
  },
  methods: {
    trainRevert(data) {
      this.controlTrain.revert = data;
      let payload = {
        _id: this.getTrain[0]._id,
        revert: data,
      };
      this.$store.dispatch("MotorRevert", payload);
    },
    trainForward(data) {
      this.controlTrain.forward = data;
      let payload = {
        _id: this.getTrain[0]._id,
        forward: data,
      };
      console.log(payload);
      this.$store.dispatch("MotorForward", payload);
    },
    setFloat() {
      let int = this.voltMotor;
      let flote = parseFloat(int).toFixed(1);
      this.voltMotor = flote;
    },
    testMotor() {
      let payload = {
        _id: this.getTrain[0]._id,
        set: this.voltMotor,
      };
      this.$store.dispatch("testMotor", payload);
    },
    emergency() {
      if (this.getAccess != undefined) {
        const id = this.getTrain[0]._id;
        let confirmFn = function () {
          try {
            let payload = {
              _id: id,
              Emergency: true,
            };
            this.$store.dispatch("setEmer", payload);
          } catch (error) {
            console.log(error);
          }
        };
        let obj = {
          message:
            '<span style="font-size: 180%; font-weight: bold;">You want to set emergency?</span>',
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "  Yes  ",
          customCloseBtnText: "Cancel",
          customConfirmBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default primary mx-4 same",
          customCloseBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default error same",
          onConfirm: confirmFn,
        };
        this.$Simplert.open(obj);
      }
    },
    clearEmer() {
      if (this.getAccess != undefined) {
        const id = this.getTrain[0]._id;
        let confirmFn = function () {
          try {
            let payload = {
              _id: id,
              Emergency: false,
            };
            this.$store.dispatch("setEmer", payload);
          } catch (error) {
            console.log(error);
          }
        };
        let obj = {
          message:
            '<span style="font-size: 180%; font-weight: bold;">You want to set clear emergency?</span>',
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "  Yes  ",
          customCloseBtnText: "Cancel",
          customConfirmBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default primary mx-4 same",
          customCloseBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default error same",
          onConfirm: confirmFn,
        };
        this.$Simplert.open(obj);
      }
    },
    cheackMode(data) {
      //console.log(this.autoAll);
      if (this.autoAll == true) {
        return "border-color: #ff5722;";
      } else if (data == true) {
        return "border-color: #FFB300;";
      } else {
        return "border-color: #039BE5;";
      }
    },
    openDialog(data) {
      if (this.getAccess.role == "Admin") {
        this.device.Name = data.Name;
        this.device.Number = data.Number;
        this.device.On = data.On;
        this.device.Auto = data.Auto;
        this.dialog = true;
      }
    },
    setMode(data) {
      console.log(this.device.Number);
      this.device.Auto = data;
      this.$store.dispatch("setMode", this.device).then(() => {
        this.autoAll = this.cheackAuto;
      });
    },
    setAutoAll() {
      this.device.Auto = true;
      this.$store.dispatch("setAutoAll", this.device).then(() => {
        this.autoAll = this.cheackAuto;
      });
    },
    setOnOff(data) {
      console.log(this.device.Number);
      this.device.On = data;
      this.$store.dispatch("setOnOff", this.device);
    },
  },
};
</script>
<style>
.control {
  width: 12vw;
  border: 2px solid;
}
.door {
  width: 12vw;
  border: 2px solid;
}
</style>
