<template>

    <canvas ref="canvas1" width="100" height="100"></canvas>
  
</template>

<script>
import { loadPlayer } from "rtsp-relay/browser";
export default {
  name: "Webcam",
  data() {
    return {
      canvas: null,
    };
  },

  mounted() {
    loadPlayer({
      url: "ws://localhost:2000/api/stream/1",
      canvas: this.$refs["canvas1"],
    });
  },
};
</script>
