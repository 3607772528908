<template>
  <div>
    <canvas ref="canvas4"></canvas>
  </div>
</template>

<script>
import { loadPlayer } from "rtsp-relay/browser";
export default {
  name: "Webcam",
  data() {
    return {
      canvas: null,
    };
  },

  mounted() {
    loadPlayer({
      url: "ws://localhost:2000/api/stream/4",
      canvas: this.$refs["canvas4"],
    });
  },
};
</script>
