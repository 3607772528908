<template>
  <div v-if="getTrain.length != 0" class="mt-2">
    <v-row class="mb-1" align="center" justify="center" style="height: 12vh">
      <div
        style="width: 7vw; position: absolute; top: 1vh; left: 2vw; z-index: 2"
      >
        <v-img :src="require('@/assets/logotop.png')"></v-img>
      </div>
      <div style="width: 10vw" class="mx-2 text-center">
        <router-link to="/" tag="button">
          <v-btn
            style="
              width: 10vw;
              height: 3vh;
              padding: 0;
              margin: 0;
              top: 0.5vh;
              left: 11vw;
              position: absolute;
            "
            class="bg"
            outlined
            :color="ispath == 'Simulation' ? '#d19208' : '#5e90cc'"
            >Simulation</v-btn
          > </router-link
        ><router-link to="/APM" tag="button">
          <v-btn
            style="
              width: 10vw;
              height: 3vh;
              padding: 0;
              margin: 0;
              top: 4vh;
              left: 11vw;
              position: absolute;
            "
            class="bg"
            outlined
            :color="ispath == 'APM' ? '#d19208' : '#5e90cc'"
            >APM</v-btn
          >
        </router-link>
        <router-link to="/operation" tag="button">
          <v-btn
            style="
              width: 10vw;
              height: 3vh;
              padding: 0;
              margin: 0;
              top: 7.5vh;
              left: 11vw;
              position: absolute;
            "
            class="bg"
            outlined
            :color="ispath == 'Operation' ? '#d19208' : '#5e90cc'"
            >operation</v-btn
          >
        </router-link>
      </div>
      <div
        style="width: 10vw; position: absolute; top: 0vh; left: 22vw"
        class="text-center mx-2"
      >
        <v-btn
          style="
            border: 1px solid #5e90cc;
            width: 10vw;
            height: 3vh;
            padding: 0;
            margin: 0;
            top: 0.5vh;
            left: 0vw;
            position: absolute;
          "
          class="mx-1"
          dark
          outlined
          v-if="getAccess != undefined"
          @click="openDialog(getTrain[0])"
          >A</v-btn
        >
        <div
          style="
            width: 10vw;
            height: 3vh;
            padding: 0;
            margin: 0;
            top: 0.5vh;
            position: absolute;
          "
          class="white--text boxtext mx-1"
          v-else
        >
          A
        </div>
        <div
          style="
            width: 10vw;
            height: 3vh;
            padding: 0;
            margin: 0;
            top: 4vh;
            position: absolute;
          "
          class="white--text boxtext mx-1"
        >
          {{ getTrain[0].Video.speed }} km/h
        </div>
        <div
          style="
            width: 10vw;
            height: 3vh;
            padding: 0;
            margin: 0;
            top: 7.5vh;
            position: absolute;
          "
          class="white--text boxtext mx-1"
        >
          {{ getTrain[0].Video.nowStation }}
        </div>
      </div>
      <div
        style="width: 10vw; position: absolute; top: 0vh; left: 34vw"
        class="text-center mx-2"
      >
        <v-btn
          style="
            border: 1px solid #5e90cc;
            width: 10vw;
            height: 3vh;
            padding: 0;
            margin: 0;
            top: 0.5vh;
            left: 0vw;
            position: absolute;
          "
          class="mx-1"
          dark
          outlined
          v-if="getAccess != undefined"
          @click="openDialog(getTrain[1])"
          >B</v-btn
        >
        <div
          style="
            padding: 0;
            margin: 0;
            top: 0.5vh;
            left: 0vw;
            position: absolute;
          "
          class="white--text boxtext mx-1"
          v-else
        >
          B
        </div>
        <div
          style="padding: 0; margin: 0; top: 4vh; left: 0vw; position: absolute"
          class="white--text boxtext mx-1"
        >
          {{ getTrain[1].Video.speed }} km/h
        </div>
        <div
          style="
            padding: 0;
            margin: 0;
            top: 7.5vh;
            left: 0vw;
            position: absolute;
          "
          class="white--text boxtext mx-1"
        >
          {{ getTrain[1].Video.nowStation }}
        </div>
      </div>
      <div
        style="width: 10vw; position: absolute; top: 0vh; left: 46vw"
        class="text-center mx-2"
      >
        <v-btn
          style="
            border: 1px solid #5e90cc;
            width: 10vw;
            height: 3vh;
            padding: 0;
            margin: 0;
            top: 0.5vh;
            left: 0vw;
            position: absolute;
          "
          class="mx-1"
          dark
          outlined
          v-if="getAccess != undefined"
          @click="openDialog(getTrain[2])"
          >C</v-btn
        >
        <div
          style="
            padding: 0;
            margin: 0;
            top: 0.5vh;
            left: 0vw;
            position: absolute;
          "
          class="white--text boxtext mx-1"
          v-else
        >
          C
        </div>
        <div
          style="padding: 0; margin: 0; top: 4vh; left: 0vw; position: absolute"
          class="white--text boxtext mx-1"
        >
          {{ getTrain[2].Video.speed }} km/h
        </div>
        <div
          style="
            padding: 0;
            margin: 0;
            top: 7.5vh;
            left: 0vw;
            position: absolute;
          "
          class="white--text boxtext mx-1"
        >
          {{ getTrain[2].Video.nowStation }}
        </div>
      </div>
      <div
        style="
          width: 15vw;
          height: 13vh;
          overflow: hidden;
          position: absolute;
          top: 0vh;
          left: 58.5vw;
        "
        class="text-center"
      >
        <v-img
          class="mr-2"
          justify="center"
          height="12vh"
          contain
          :src="require('@/assets/KMUTNB.png')"
        ></v-img>
      </div>
      <div
        style="width: 10vw; position: absolute; top: 0vh; left: 74.5vw"
        class="text-center mx-2"
        v-if="getAccess != undefined"
      >
        <div style="font-size: 2.5vh; height: 3vh" class="white--text">
          Username Login
        </div>
        <h3
          class="white--text"
          style="
            width: 100%;
            height: 3vh;
            border: 1px solid white;
            border-radius: 5px;
            font-size: 2.7vh;
            font-weight: 300;
          "
        >
          {{ getAccess.username }}
        </h3>

        <v-btn
          style="width: 100%; height: 2.2vh; font-size: 1vh; top: 0vh"
          class="mt-1"
          @click="logout"
        >
          Logout
        </v-btn>
      </div>
      <div
        style="width: 10vw; position: absolute; top: 3vh; left: 74.5vw"
        class="text-center mx-2"
        v-else
      >
        <v-btn
          style="width: 100%; height: 3vh; font-size: 7vh"
          class="mt-2"
          @click="openLogin"
        >
          Login
        </v-btn>
      </div>
      <div
        style="width: 10vw; position: absolute; top: 1vh; left: 87vw"
        class="text-center ml-2"
      >
        <h2 style="width: 10vw" class="white--text boxtime">
          <div class="ma-n1 pa-0">
            {{ time }}
          </div>
          {{ showData }}
        </h2>
      </div>
    </v-row>
    <v-dialog max-width="380" v-model="dialog">
      <v-card>
        <div align="end" justify="center">
          <v-btn icon color="error" @click="dialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
        <div align="center" justify="center" class="mt-n8">
          <span class="text-center" style="font-size: 250%">
            {{ this.train.Name }}
          </span>
        </div>
        <v-card-text class="text--primary my-0 ay-0">
          <div style="border: solid; border-radius: 5px" class="mb-1">
            <v-row align="center" class="mx-2 mt-1">
              <v-col cols="3" style="font-size: 150%">Speed: </v-col>
              <v-col cols="4" align="center">
                <v-text-field
                  id="input"
                  v-model="train.Speed"
                  placeholder="Set Speed"
                  min="0"
                  step="10"
                  max="100"
                  hide-details
                  single-line
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="2" style="font-size: 150%" class="ma-0 pa-0">
                km/hr
              </v-col>
              <v-col cols="1" style="font-size: 150%" align="end">
                <v-btn
                  dense
                  style="width: 20%; height: 30px"
                  color="success"
                  @click="setSpeed()"
                >
                  SAVE</v-btn
                >
              </v-col>
            </v-row>
            <v-row class="text-center pa-0 ma-0">
              <v-col>
                <v-btn
                  dense
                  dark
                  style="width: 20%; height: 30px"
                  :color="train.Start ? 'success' : 'grey'"
                  @click="setStart(true)"
                >
                  Start
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  dense
                  dark
                  style="width: 20%; height: 30px"
                  :color="train.Start ? 'grey' : 'error'"
                  @click="setStart(false)"
                >
                  Stop
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center" class="pa-0 ma-0 mb-2">
              <v-btn
                class="text-center"
                color="primary"
                style="width: 20%; height: 30px"
                @click="setClear()"
              >
                clear
              </v-btn>
            </v-row>
          </div>
          <div style="border: solid; border-radius: 5px">
            <v-row align="center" justify="center" class="mt-1">
              <div class="text-center mt-2" style="font-size: 150%">
                Select Station
              </div>
            </v-row>
            <v-row v-if="train.selectStation != null" class="ma-1">
              <v-col
                ><div class="text-center" style="font-size: 150%">INBOUND</div>
                <Checkbox
                  v-for="item in train.selectStation.slice(0, 9)"
                  :key="item._id"
                  v-model="item.Enable"
                  @change="setEnable(item.Number)"
                  >{{ item.Name }}</Checkbox
                >
              </v-col>
              <v-col
                ><div class="text-center" style="font-size: 150%">OUTBOUND</div>
                <Checkbox
                  id="checkbox"
                  v-for="item in train.selectStation.slice(9, 17)"
                  :key="item._id"
                  v-model="item.Enable"
                  @change="setEnable(item.Number)"
                  >{{ item.Name }}</Checkbox
                >
                <Checkbox
                  id="checkbox"
                  v-model="train.selectStation[8].Enable"
                  @change="setEnable(train.selectStation[8].Number)"
                  >{{ train.selectStation[8].Name }}</Checkbox
                >
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="380" v-model="dialogLogin">
      <v-card class="elevation-1 pa-3" width="100%">
        <v-card-text>
          <v-form @keyup.native.enter="login">
            <v-text-field
              append-icon="mdi-account"
              name="username"
              label="Username"
              type="text"
              v-model="user.username"
              :rules="[rules.required]"
              color="#000"
              @keypress="isLetter($event)"
            />
            <v-text-field
              :type="hidePassword ? 'password' : 'text'"
              :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye-outline'"
              name="password"
              label="Password"
              id="password"
              :rules="[rules.required]"
              v-model="user.password"
              @click:append="hidePassword = !hidePassword"
              @keypress="isLetter($event)"
              color="#000"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn class="white--text" color="#000" @click="login">Login</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogLogin = false"> cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="380" v-model="askAdmin">
      <v-card class="elevation-1 pa-3" width="100%">
        <v-card-text class="mt-2">
          <h2 class="text-center">มีผู้ใช้งานคนอื่นต้องการ login</h2>
        </v-card-text>
        <v-card-actions>
          <v-btn class="white--text" color="#000" @click="logout">Logout</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="askAdmin = false"> cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <simplert />
    <v-divider
      style="border: 2px solid #5e90cc; border-radius: 1px"
    ></v-divider>
    <v-snackbar v-model="showResult" timeout="3000" top>
      {{ result }}
    </v-snackbar>
  </div>
</template>

<script>
import Checkbox from "vue-material-checkbox";
export default {
  components: { Checkbox },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    getTrain() {
      return this.$store.getters.alltrain;
    },
  },
  data: () => ({
    askAdmin: false,
    checkbox: true,
    train: {
      _id: null,
      No: null,
      Name: null,
      Speed: null,
      Start: null,
      selectStation: null,
    },
    ispath: null,
    interval: null,
    showData: null,
    time: null,
    dialog: false,
    dialogLogin: false,
    user: {
      username: null,
      password: null,
    },
    showResult: false,
    result: "",
    hidePassword: true,
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),
  watch: {
    $route(to) {
      //console.log(to);
      this.ispath = to.name;
    },
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.$store.dispatch("getTrains");
    this.$store.dispatch("auth/decode");
    this.checkPath();
    this.interval = setInterval(() => {
      this.showData = new Date().toLocaleDateString("th-TH");
      this.time = new Date().toLocaleTimeString();
    }, 1000);
  },
  mounted() {
    this.sockets.subscribe("EVENT_NAME", (data) => {
      console.log(data);
    });
    this.sockets.subscribe("login", (data) => {
      console.log(this.getAccess);
      if (data == 400 && this.getAccess != undefined) {
        this.askAdmin = true;
      }
    });
  },
  methods: {
    checkPath() {
      this.ispath = this.$router.currentRoute.name;
      console.log(this.ispath);
    },
    openLogin() {
      this.dialogLogin = true;
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    login() {
      if (!this.user.username || !this.user.password) {
        this.result = "Username and Password can't be null.";
        this.showResult = true;
        return;
      } else {
        this.$store.dispatch("auth/login", this.user).then(
          (res) => {
            console.log(res);
            if (res.login == true) {
              this.loading = false;
              this.error = true;
              this.result =
                "ขณะนี้มีผู้ใช้งานบัญชี Admin อยู่ กรุณาลองใหม่อีกครั้ง";
              this.showResult = true;
            } else {
              this.$store.dispatch("auth/decode").then(() => {
                this.dialogLogin = false;
              });
            }
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this.error = true;
            this.result = error.data.error.message;
            this.showResult = true;
          }
        );
      }
    },
    openDialog(data) {
      console.log(data.selectStation);
      this.dialog = true;
      this.train._id = data._id;
      this.train.Speed = data.Speed;
      this.train.Start = data.Start;
      this.train.Name = data.name;
      this.train.selectStation = data.selectStation;
      if (data.name == "trainA") {
        this.train.No = 0;
      } else if (data.name == "trainB") {
        this.train.No = 1;
      } else {
        this.train.No = 2;
      }
    },
    setStart(data) {
      this.train.Start = data;
      this.$store.dispatch("setStart", this.train);
    },
    setSpeed() {
      this.$store.dispatch("setSpeed", this.train);
    },
    setEnable(Number) {
      const index = this.train.selectStation.findIndex(
        (s) => s.Number == Number
      );
      console.log(this.train.selectStation);
      let payload = {
        _id: this.train._id,
        No: this.train.No,
        Number: Number,
        index: index,
        Enable: this.train.selectStation[index].Enable,
      };
      console.log(payload);
      this.$store.dispatch("setEnable", payload);
    },
    setClear() {
      this.train.Speed = 0;
      this.train.Start = false;
      for (let i = 0; i < this.train.selectStation.length; i++) {
        this.train.selectStation[i].Enable = false;
      }
      this.$store.dispatch("setClear", this.train);
    },
    logout() {
      this.askAdmin = false;
      let confirmFn = function () {
        try {
          this.$store.dispatch("auth/logout").then(() => {
            this.$store.dispatch("auth/decode");
            //this.$router.push("/");
          });
        } catch (error) {
          //console.log(error);
        }
      };
      let obj = {
        message:
          '<span style="font-size: 180%; font-weight: bold;">You want to log out?</span>',
        type: "warning",
        useConfirmBtn: true,
        customConfirmBtnText: "  Yes  ",
        customCloseBtnText: "Cancel",
        customConfirmBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default primary mx-4 same",
        customCloseBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default error same",
        onConfirm: confirmFn,
      };
      this.$Simplert.open(obj);
    },
  },
};
</script>
<style>
.bg {
  background-color: rgb(255, 255, 255, 0.1);
  border-width: 2px;
}
.boxtext {
  width: 10vw;
  height: 3vh;
  border: 1px solid #5e90cc;
  border-radius: 0.5vh;
  font-size: 2.7vh;
}
.boxtime {
  width: 10vw;
  height: 10vh;
  border: 2px solid #5e90cc;
  border-radius: 5px;
  font-size: 4vh;
  font-weight: 300;
}
.v-btn--disabled {
  pointer-events: none;
}
.disabled {
  pointer-events: none;
}
</style>
