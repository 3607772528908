<template>
  <div v-if="this.getTrain[0]" style="hight: ">
    <v-btn
      outlined
      color="#d19208"
      style="position: absolute; left: 1%; top: 2%; z-index: 1"
      @click="reset"
      >reset</v-btn
    >
    <vue-plyr ref="plyr" :options="options" style="z-index: -1">
      <video width="500">
        <source src="/video/front.mp4" type="video/mp4" />
      </video>
    </vue-plyr>
    <v-col
      style="position: absolute; left: 48%; top: 70%; z-index: 1"
      class="ma-0 pa-0"
    >
      <div class="white--text textxl">
        {{ getTrain[this.No].Video.TimeCount }}s
      </div>
    </v-col>
    <v-col
      style="position: absolute; left: 20%; top: 90%; z-index: 1"
      class="mb-n1 pa-0"
    >
      <div class="white--text textxl">
        From {{ getTrain[this.No].Video.nowStation }}
      </div>
    </v-col>
    <v-col
      style="position: absolute; left: 40%; top: 90%; z-index: 1"
      class="mb-n1 pa-0"
    >
      <div class="white--text textxl">
        Speed {{ getTrain[this.No].Speed }}km/hr
      </div>
    </v-col>
    <v-col
      style="position: absolute; left: 75%; top: 90%; z-index: 1"
      class="mb-n1 pa-0"
    >
      <div class="white--text textxl">
        To {{ getTrain[this.No].Video.nextStation }}
      </div>
    </v-col>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "Video",
  data() {
    return {
      No: 0,
      options: {
        clickToPlay: false,
        keyboard: { focused: false, global: false },
        fullscreen: {
          enabled: false,
          fallback: false,
          iosNative: false,
          container: null,
        },

        controls: [],
        settings: [],
      },
    };
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    getTrain() {
      return this.$store.getters.alltrain;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    if (this.$route.params.data != null) {
      this.No = this.$route.params.data;
      Cookies.set("noTrain", this.No);
      this.$store.dispatch("getTrains", this.id);
    } else {
      this.No = Cookies.get("noTrain");
      this.$store.dispatch("getTrains", this.id);
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    this.sockets.subscribe("STATUS", (data) => {
      console.log(data.name);
      if (this.getTrain[this.No].name == data.name) {
        const status = JSON.parse(data.status);
        console.log(status.Event);
        if (status.Event == 0) {
          this.$refs.plyr.player.pause();
        } else if (status.Event == 1) {
          console.log(data.name);
          this.$refs.plyr.player.play();
        }
      }
    });
    this.sockets.subscribe("speed", (data) => {
      console.log(data.name);
      if (this.getTrain[this.No].name == data.name) {
        this.$refs.plyr.player.speed = 3;
      }
    });
  },
  methods: {
    reset() {
      this.$refs.plyr.player.stop();
    },
  },
};
</script>
