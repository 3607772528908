import Vue from "vue";
import VueRouter from "vue-router";
//import Login from "@/views/Login.vue";
import Simulation from "@/views/Simulation.vue";
import Operation from "@/views/Operation.vue";
import Accounts from "@/views/Accounts.vue";
import FrontVideo from "@/views/FrontVideo";
import BackVideo from "@/views/BackVideo";
import WebCam from "@/views/WebCam";
import ResetPassword from "../views/ResetPassword.vue";
import APM from "@/views/APM";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Simulation",
    component: Simulation,
  },
  {
    path: "/operation",
    name: "Operation",
    component: Operation,
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: ResetPassword,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/accounts",
    name: "Account",
    component: Accounts,
  },

  {
    path: "/APM",
    name: "APM",
    component: APM,
  },
  {
    path: "/frontVideo",
    name: "FrontVideo",
    component: FrontVideo,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/backVideo",
    name: "BackVideo",
    component: BackVideo,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/webcam",
    name: "WebCam",
    component: WebCam,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
