import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import vuetify from "./plugins/vuetify";
import { Simplert } from "vue2-simplert-plugin";
import "vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css";

import VueSocketIO from "vue-socket.io";
const socketURL = process.env.VUE_APP_SOCKET_URL;
Vue.use(
  new VueSocketIO({
    //debug: true,
    connection: socketURL,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);

Vue.config.productionTip = false;
Vue.use(Simplert);
Vue.use(VuePlyr, {
  plyr: {},
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
