<template>
  <div v-if="getTrain[0]">
    <v-row justify="center" style="overflow: hidden; height: 50vh">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.0"
        x="0px"
        y="0px"
        viewBox="0 0 1280 740"
        height="100vh"
        style="margin: -23vh -3rem 0 -3rem"
      >
        <defs>
          <marker
            id="startarrow"
            markerWidth="7"
            markerHeight="6"
            refX="7"
            refY="3.5"
            orient="auto"
          >
            <polygon points="10 0, 10 7, 0 3.5" fill="red" />
          </marker>
          <marker
            id="endarrow"
            markerWidth="7"
            markerHeight="5"
            refX="0"
            refY="2.5"
            orient="auto"
          >
            <polygon points="0 0, 7 2.5, 0 5" fill="red" />
          </marker>
        </defs>
        <g id="line1">
          <text x="180" y="360" class="st4">{{ showText("11") }}</text>
          <line
            :class="showLine('11')"
            x1="170"
            x2="190"
            y1="340"
            y2="340"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf1in">
          <text x="220" y="320" class="st4">{{ showText("12") }}</text>
          <line
            :class="showLine('12')"
            x1="210"
            x2="230"
            y1="300"
            y2="300"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="line2in">
          <text x="260" y="280" class="st4">{{ showText("13") }}</text>
          <line
            :class="showLine('13')"
            x1="250"
            x2="270"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf2in">
          <text x="310" y="280" class="st4">{{ showText("14") }}</text>
          <line
            :class="showLine('14')"
            x1="300"
            x2="320"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="line3in">
          <text x="380" y="280" class="st4">{{ showText("15") }}</text>
          <line
            :class="showLine('15')"
            x1="370"
            x2="390"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf3in">
          <text x="444" y="280" class="st4">{{ showText("16") }}</text>
          <line
            :class="showLine('16')"
            x1="435"
            x2="455"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="line4in">
          <text x="508" y="280" class="st4">{{ showText("17") }}</text>
          <line
            :class="showLine('17')"
            x1="500"
            x2="520"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf4in">
          <text x="572" y="280" class="st4">{{ showText("18") }}</text>
          <line
            :class="showLine('18')"
            x1="564"
            x2="584"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="line5in">
          <text x="635" y="280" class="st4">{{ showText("19") }}</text>
          <line
            :class="showLine('19')"
            x1="626"
            x2="646"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf5in">
          <text x="705" y="280" class="st4">{{ showText("110") }}</text>
          <line
            :class="showLine('110')"
            x1="696"
            x2="716"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="line6in">
          <text x="774" y="280" class="st4">{{ showText("111") }}</text>
          <line
            :class="showLine('111')"
            x1="765"
            x2="785"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf6in">
          <text x="836" y="280" class="st4">{{ showText("112") }}</text>
          <line
            :class="showLine('112')"
            x1="828"
            x2="848"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="line7in">
          <text x="897" y="280" class="st4">{{ showText("113") }}</text>
          <line
            :class="showLine('113')"
            x1="890"
            x2="910"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf7in">
          <text x="958" y="280" class="st4">{{ showText("114") }}</text>
          <line
            :class="showLine('114')"
            x1="950"
            x2="970"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="line8in">
          <text x="1007" y="280" class="st4">{{ showText("115") }}</text>
          <line
            :class="showLine('115')"
            x1="998"
            x2="1018"
            y1="260"
            y2="260"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf8in">
          <text x="1045" y="328" class="st4">{{ showText("116") }}</text>
          <line
            :class="showLine('116')"
            x1="1035"
            x2="1055"
            y1="305"
            y2="305"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="line9">
          <text x="1095" y="365" class="st4">{{ showText("117") }}</text>
          <line
            :class="showLine('117')"
            x1="1080"
            x2="1100"
            y1="343"
            y2="343"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf9out">
          <text x="1070" y="405" class="st4">{{ showText("218") }}</text>
          <line
            :class="showLine('218')"
            x1="1068"
            x2="1088"
            y1="385"
            y2="385"
            stroke="red"
            stroke-width="2"
            marker-start="url(#startarrow)"
          />
        </g>
        <g id="line8out">
          <text x="1018" y="452" class="st4">{{ showText("219") }}</text>
          <line
            :class="showLine('219')"
            x1="1036"
            x2="1016"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf8out">
          <text x="960" y="452" class="st4">{{ showText("220") }}</text>
          <line
            :class="showLine('220')"
            x1="958"
            x2="978"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-start="url(#startarrow)"
          />
        </g>
        <g id="line7out">
          <text x="897" y="452" class="st4">{{ showText("221") }}</text>
          <line
            :class="showLine('221')"
            x1="915"
            x2="895"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf7out">
          <text x="835" y="452" class="st4">{{ showText("222") }}</text>
          <line
            :class="showLine('222')"
            x1="832"
            x2="852"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-start="url(#startarrow)"
          />
        </g>
        <g id="line6out">
          <text x="774" y="452" class="st4">{{ showText("223") }}</text>
          <line
            :class="showLine('223')"
            x1="792"
            x2="772"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf6out">
          <text x="705" y="452" class="st4">{{ showText("224") }}</text>
          <line
            :class="showLine('224')"
            x1="702"
            x2="722"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-start="url(#startarrow)"
          />
        </g>
        <g id="line5out">
          <text x="636" y="452" class="st4">{{ showText("225") }}</text>
          <line
            :class="showLine('225')"
            x1="652"
            x2="632"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf5out">
          <text x="572" y="452" class="st4">{{ showText("226") }}</text>
          <line
            :class="showLine('226')"
            x1="570"
            x2="590"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-start="url(#startarrow)"
          />
        </g>
        <g id="line4out">
          <text x="508" y="452" class="st4">{{ showText("227") }}</text>
          <line
            :class="showLine('227')"
            x1="525"
            x2="505"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf4out">
          <text x="444" y="452" class="st4">{{ showText("228") }}</text>
          <line
            :class="showLine('228')"
            x1="440"
            x2="460"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-start="url(#startarrow)"
          />
        </g>
        <g id="line3out">
          <text x="380" y="452" class="st4">{{ showText("229") }}</text>
          <line
            :class="showLine('229')"
            x1="396"
            x2="376"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf3out">
          <text x="317" y="452" class="st4">{{ showText("230") }}</text>
          <line
            :class="showLine('230')"
            x1="315"
            x2="335"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-start="url(#startarrow)"
          />
        </g>
        <g id="line2out">
          <text x="255" y="452" class="st4">{{ showText("231") }}</text>
          <line
            :class="showLine('231')"
            x1="272"
            x2="252"
            y1="432"
            y2="432"
            stroke="red"
            stroke-width="2"
            marker-end="url(#endarrow)"
          />
        </g>
        <g id="buf1out">
          <text x="210" y="400" class="st4">{{ showText("232") }}</text>
          <line
            :class="showLine('232')"
            x1="210"
            x2="230"
            y1="380"
            y2="380"
            stroke="red"
            stroke-width="2"
            marker-start="url(#startarrow)"
          />
        </g>
        <g id="map">
          <rect x="256" y="238" class="st0" width="768" height="6" />

          <rect
            x="117.94"
            y="295.32"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -154.7544 227.0345)"
            class="st0"
            width="157.48"
            height="6"
          />

          <rect
            x="1004.94"
            y="295.32"
            transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 1637.5935 1278.9581)"
            class="st0"
            width="157.48"
            height="6"
          />
          <rect x="256" y="471.54" class="st0" width="768" height="6" />

          <rect
            x="117.94"
            y="414.21"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 354.0341 -16.2874)"
            class="st0"
            width="157.48"
            height="6"
          />

          <rect
            x="1004.94"
            y="414.21"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 2146.3821 -50.6341)"
            class="st0"
            width="157.48"
            height="6"
          />
          <polygon class="st0" points="256,238 248.82,241.11 255.89,248.18  " />
          <polygon
            class="st0"
            points="137.46,352.46 137.46,367.07 144.54,360 144.54,359.54  "
          />
          <polygon
            class="st0"
            points="255.89,471.36 248.82,478.43 256,481.54 256,471.54  "
          />
          <polygon
            class="st0"
            points="1024,471.54 1024,481.54 1031.54,478.43 1024.46,471.36  "
          />
          <polygon
            class="st0"
            points="1135.82,359.54 1142.89,352.46 1142.89,367.07 1135.82,360  "
          />
          <polygon
            class="st0"
            points="1024,238 1031.54,241.11 1024.46,248.18 1024,248  "
          />
        </g>
        <g id="big_x5F_state">
          <circle
            id="11"
            class="st0 st5"
            :class="cheackStatus('11')"
            cx="140"
            cy="357"
            r="12"
          />
          <circle
            id="13"
            class="st0 st5"
            :class="cheackStatus('13')"
            cx="256"
            cy="240"
            r="12"
          />
          <circle
            id="15"
            class="st0 st5"
            :class="cheackStatus('15')"
            cx="384"
            cy="240"
            r="12"
          />
          <circle
            id="17"
            class="st0 st5"
            :class="cheackStatus('17')"
            cx="512"
            cy="240"
            r="12"
          />
          <circle
            id="19"
            class="st0 st5"
            :class="cheackStatus('19')"
            cx="640"
            cy="240"
            r="12"
          />
          <circle
            id="111"
            class="st0 st5"
            :class="cheackStatus('111')"
            cx="778.33"
            cy="240"
            r="12"
          />
          <circle
            id="113"
            class="st0 st5"
            :class="cheackStatus('113')"
            cx="901.17"
            cy="240"
            r="12"
          />
          <circle
            id="115"
            class="st0 st5"
            :class="cheackStatus('115')"
            cx="1024"
            cy="240"
            r="12"
          />
          <circle
            id="117"
            class="st0 st5"
            :class="cheackStatus('117')"
            cx="1140"
            cy="360.5"
            r="12"
          />
          <circle
            id="231"
            class="st0 st5"
            :class="cheackStatus('231')"
            cx="256"
            cy="473"
            r="12"
          />
          <circle
            id="229"
            class="st0 st5"
            :class="cheackStatus('229')"
            cx="384"
            cy="473"
            r="12"
          />
          <circle
            id="227"
            class="st0 st5"
            :class="cheackStatus('227')"
            cx="512"
            cy="473"
            r="12"
          />
          <circle
            id="225"
            class="st0 st5"
            :class="cheackStatus('225')"
            cx="640"
            cy="473"
            r="12"
          />
          <circle
            id="223"
            class="st0 st5"
            :class="cheackStatus('223')"
            cx="778.33"
            cy="473"
            r="12"
          />
          <circle
            id="221"
            class="st0 st5"
            :class="cheackStatus('221')"
            cx="901.17"
            cy="473"
            r="12"
          />
          <circle
            id="219"
            class="st0 st5"
            :class="cheackStatus('219')"
            cx="1024"
            cy="473"
            r="12"
          />
        </g>
        <g id="small_x5F_state">
          <circle
            class="st0"
            :class="cheackStatusSmall('12')"
            cx="197"
            cy="297"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('14')"
            cx="314.5"
            cy="241"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('16')"
            cx="448.5"
            cy="241"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('18')"
            cx="576.5"
            cy="241"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('110')"
            cx="709.5"
            cy="241"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('112')"
            cx="839.5"
            cy="241"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('114')"
            cx="962.5"
            cy="241"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('116')"
            cx="1082"
            cy="300"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('232')"
            cx="198"
            cy="417"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('230')"
            cx="320.5"
            cy="474"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('228')"
            cx="448.5"
            cy="474"
            r="9"
          />
          <circle
            class="st0 st5"
            :class="cheackStatusSmall('226')"
            cx="576.5"
            cy="474"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('224')"
            cx="709.5"
            cy="474"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('222')"
            cx="839.5"
            cy="474"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('220')"
            cx="962.5"
            cy="474"
            r="9"
          />
          <circle
            class="st0"
            :class="cheackStatusSmall('218')"
            cx="1086"
            cy="420"
            r="9"
          />
        </g>
        <g id="text">
          <text transform="matrix(1 0 0 1 67.1501 363.522)" class="st4">
            1.TGGS
          </text>
          <text transform="matrix(1 0 0 1 238.8389 218.1934)" class="st4">
            2.STRI
          </text>
          <text transform="matrix(1 0 0 1 343.0204 218.4517)" class="st4">
            3.THAI-FRENCH
          </text>
          <text transform="matrix(1 0 0 1 496.3114 218.0322)" class="st4">
            4.FTE
          </text>
          <text transform="matrix(1 0 0 1 625.1289 218.4194)" class="st4">
            5.CIT
          </text>
          <text transform="matrix(1 0 0 1 740.7103 218.3872)" class="st4">
            6.SCIENCE
          </text>
          <text transform="matrix(1 0 0 1 856.2497 218.4517)" class="st4">
            7.ENGINEERING
          </text>
          <text transform="matrix(1 0 0 1 984.167 218.9678)" class="st4">
            8.SOCCER FIELD
          </text>
          <text transform="matrix(1 0 0 1 1174.2725 363.5166)" class="st4">
            9.TECHNO
          </text>
          <text transform="matrix(1 0 0 1 1174.2725 382)" class="st4">
            PARK
          </text>
          <text transform="matrix(1 0 0 1 984.167 511.8895)" class="st4">
            8.SOCCER FIELD
          </text>
          <text transform="matrix(1 0 0 1 857.2162 511.8895)" class="st4">
            7.ENGINEERING
          </text>
          <text transform="matrix(1 0 0 1 742.6443 511.8895)" class="st4">
            6.SCIENCE
          </text>
          <text transform="matrix(1 0 0 1 628.0295 511.8895)" class="st4">
            5.CIT
          </text>
          <text transform="matrix(1 0 0 1 499.1588 511.8895)" class="st4">
            4.FTE
          </text>
          <text transform="matrix(1 0 0 1 345.815 511.8895)" class="st4">
            3.THAI-FRENCH
          </text>
          <text transform="matrix(1 0 0 1 241.5806 511.8895)" class="st4">
            2.STRI
          </text>
        </g>
      </svg>
    </v-row>
    <v-divider
      style="border: 2px solid #5e90cc; border-radius: 1px"
    ></v-divider>
    <v-row
      justify="start"
      class="mt-1"
      style="height: 20vh"
    >
      <v-col
        cols="2"
        v-if="getTrain[0].Emergency == false && getAccess != undefined"
      >
        <div @click="emergency()">
          <v-img
            class="st5"
            justify="center"
            width="30vh"
            :class="getAccess == undefined ? 'disabled' : ''"
            :src="require('@/assets/red-btn.svg')"
          ></v-img>
        </div>
      </v-col>
      <v-col
        cols="2"
        v-if="getTrain[0].Emergency == true && getAccess != undefined"
      >
        <div @click="clearEmer()">
          <v-img
            class="st5"
            justify="center"
            width="30vh"
            :class="getAccess == undefined ? 'disabled' : ''"
            :src="require('@/assets/yellow-btn.svg')"
          ></v-img>
        </div>
      </v-col>
      <v-col cols="2" v-if="getAccess == undefined"> </v-col>
      <v-col
        cols="9"
        class="mx-1"
        justify="center"
        id="showCard"
        style="display: none"
      >
        <div
          class="white--text text-center"
          style="letter-spacing: 5px; font-size: 3vh"
        >
          {{ showStation.name }} ({{ showStation.bound }})
        </div>
        <v-card
          outlined
          class="mt-1"
          color="#000"
          style="border-color: white; border-width: 4px"
        >
          <div align="end" justify="center">
            <v-btn icon color="error" @click="closeCard()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </div>
          <v-row align="center" justify="center" class="mt-n8 py-0">
            <v-col cols="3" align="center" justify="center" class="mt-0 py-0">
              <div class="white--text st6 mt-0 py-0">A</div>
              <div class="white--text st6 py-0">
                {{ this.getTrain[0].Speed }} km/h
              </div>
            </v-col>
            <v-col class="mt-0 py-0">
              <v-row align="center" justify="center">
                <v-col cols="5">
                  <span class="white--text st6"
                    >Arriving '{{ showStation.name }}' In</span
                  >
                </v-col>

                <v-col cols="3" align="center" justify="center" class="my-n10">
                  <span class="white--text st6">Train A</span>
                  <v-icon class="red--text" style="font-size: 4vh">
                    mdi-arrow-right-thin
                  </v-icon>
                </v-col>
                <v-col cols="4" align="center" justify="center">
                  <span
                    class="white--text st6"
                    v-if="showStation.bound == 'OUTBOUND'"
                  >
                    {{ this.getTrain[0].Station[showStation.No].ArrivingTime }}
                    min.
                  </span>
                  <span class="white--text st6" v-else>
                    {{ this.getTrain[0].Station[showStation.No].ArrivingTime }}
                    min.
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="mb-n2 mt-n2 py-0">
            <v-col cols="3" align="center" justify="center">
              <div class="white--text st6">B</div>
              <div class="white--text st6 py-0">
                {{ this.getTrain[1].Speed }} km/h
              </div>
            </v-col>
            <v-col>
              <v-row align="center" justify="center">
                <v-col cols="5">
                  <span class="white--text st6"
                    >Arriving '{{ showStation.name }}' In</span
                  >
                </v-col>

                <v-col cols="3" align="center" justify="center" class="my-n10">
                  <span class="white--text st6">Train B</span>
                  <v-icon class="red--text my-n2" style="font-size: 4vh">
                    mdi-arrow-right-thin
                  </v-icon>
                </v-col>
                <v-col cols="4" align="center" justify="center">
                  <span
                    class="white--text st6"
                    v-if="showStation.bound == 'OUTBOUND'"
                  >
                    {{ this.getTrain[1].Station[showStation.No].ArrivingTime }}
                    min.
                  </span>
                  <span class="white--text st6" v-else>
                    {{ this.getTrain[1].Station[showStation.No].ArrivingTime }}
                    min.
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="mt-n5 py-0">
            <v-col cols="3" align="center" justify="center">
              <div class="white--text st6">C</div>
              <div class="white--text st6 py-0">
                {{ this.getTrain[2].Speed }} km/h
              </div>
            </v-col>
            <v-col>
              <v-row align="center" justify="center">
                <v-col cols="5">
                  <span class="white--text st6"
                    >Arriving '{{ showStation.name }}' In</span
                  >
                </v-col>

                <v-col cols="3" align="center" justify="center" class="my-n10">
                  <span class="white--text st6">Train C</span>
                  <v-icon class="red--text my-n2" style="font-size: 4vh">
                    mdi-arrow-right-thin
                  </v-icon>
                </v-col>
                <v-col cols="4" align="center" justify="center">
                  <span
                    class="white--text st6"
                    v-if="showStation.bound == 'OUTBOUND'"
                  >
                    {{ this.getTrain[2].Station[showStation.No].ArrivingTime }}
                    min.
                  </span>
                  <span class="white--text st6" v-else>
                    {{ this.getTrain[2].Station[showStation.No].ArrivingTime }}
                    min.
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import "d3";
import * as d3 from "d3";

export default {
  name: "operation",

  data() {
    return {
      scale: 2,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      card: false,
      trains: null,
      showStation: {
        No: 0,
        name: null,
        bound: null,
        speedA: null,
        timeA: null,
        speedB: null,
        timeB: null,
        speedC: null,
        timeC: null,
      },
    };
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    getTrain() {
      return this.$store.getters.alltrain;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.$store.dispatch("getTrains");
    this.atClick();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = document.documentElement.clientWidth - 100;
      this.height = document.documentElement.clientHeight;
    },
    emergency() {
      if (this.getAccess != undefined) {
        const id = this.getTrain[0]._id;
        let confirmFn = function () {
          try {
            let payload = {
              _id: id,
              Emergency: true,
            };
            this.$store.dispatch("setEmer", payload);
          } catch (error) {
            console.log(error);
          }
        };
        let obj = {
          message:
            '<span style="font-size: 180%; font-weight: bold;">You want to set emergency?</span>',
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "  Yes  ",
          customCloseBtnText: "Cancel",
          customConfirmBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default primary mx-4 same",
          customCloseBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default error same",
          onConfirm: confirmFn,
        };
        this.$Simplert.open(obj);
      }
    },
    clearEmer() {
      if (this.getAccess != undefined) {
        const id = this.getTrain[0]._id;
        let confirmFn = function () {
          try {
            let payload = {
              _id: id,
              Emergency: false,
            };
            this.$store.dispatch("setEmer", payload);
          } catch (error) {
            console.log(error);
          }
        };
        let obj = {
          message:
            '<span style="font-size: 180%; font-weight: bold;">You want to set clear emergency?</span>',
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "  Yes  ",
          customCloseBtnText: "Cancel",
          customConfirmBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default primary mx-4 same",
          customCloseBtnClass:
            "v-btn v-btn--has-bg theme--light v-size--default error same",
          onConfirm: confirmFn,
        };
        this.$Simplert.open(obj);
      }
    },
    async atClick() {
      let name;
      let showStation = this.showStation;
      let trains;
      await this.$store.dispatch("getTrains").then(() => {
        trains = this.getTrain;
      });
      //console.log(trains);
      d3.select("div").on("click", function (event, d) {
        //console.log(d);
        name = event.target.id;
        //console.log(name);
        if (
          name != "" &&
          name != "password" &&
          name.search("input") == -1 &&
          name.search("checkbox") == -1
        ) {
          let start = name.slice(0, 1);
          let num = name.slice(1);
          //console.log(num);
          showStation.No = num - 1;
          showStation.name = trains[0].nameStation[num].Name;
          if (start == 1) {
            showStation.bound = "OUTBOUND";
          }
          if (start == 2) {
            showStation.bound = "INBOUND";
          }
          document.getElementById("showCard").style.display = "block";
        }
        //console.log(name);
      });
    },
    closeCard() {
      document.getElementById("showCard").style.display = "none";
    },
    showText(data) {
      let start = data.slice(0, 1);
      let num = data.slice(1);
      if (num == 1 || num == 33) {
        if (
          this.getTrain[0].Station[1].Status == 2 ||
          this.getTrain[0].Station[33].Status == 2
        ) {
          return "A";
        } else if (
          this.getTrain[1].Station[1].Status == 2 ||
          this.getTrain[1].Station[33].Status == 2
        ) {
          return "B";
        } else if (
          this.getTrain[2].Station[1].Status == 2 ||
          this.getTrain[2].Station[33].Status == 2
        ) {
          return "C";
        } else {
          return "";
        }
      }
      if (start == 1) {
        if (this.getTrain[0].Station[num].Status == 2) {
          return "A";
        } else if (this.getTrain[1].Station[num].Status == 2) {
          return "B";
        } else if (this.getTrain[2].Station[num].Status == 2) {
          return "C";
        } else {
          return "";
        }
      } else {
        if (this.getTrain[0].Station[num].Status == 2) {
          return "A";
        } else if (this.getTrain[1].Station[num].Status == 2) {
          return "B";
        } else if (this.getTrain[2].Station[num].Status == 2) {
          return "C";
        } else {
          return "";
        }
      }
    },
    showLine(data) {
      //let start = data.slice(0, 1);
      let num = data.slice(1);
      if (num == 1 || num == 33) {
        if (
          this.getTrain[0].Station[1].Status == 2 ||
          this.getTrain[1].Station[1].Status == 2 ||
          this.getTrain[2].Station[1].Status == 2 ||
          this.getTrain[0].Station[33].Status == 2 ||
          this.getTrain[1].Station[33].Status == 2 ||
          this.getTrain[2].Station[33].Status == 2
        ) {
          return "st8";
        } else {
          return "st9";
        }
      } else {
        if (
          this.getTrain[0].Station[num].Status == 2 ||
          this.getTrain[1].Station[num].Status == 2 ||
          this.getTrain[2].Station[num].Status == 2
        ) {
          return "st8";
        } else {
          return "st9";
        }
      }
    },
    cheackStatus(data) {
      //let start = data.slice(0, 1);
      let num = data.slice(1);
      if (num == 1 || num == 33) {
        let status =
          this.getTrain[0].Station[1].Status +
          this.getTrain[1].Station[1].Status +
          this.getTrain[2].Station[1].Status +
          this.getTrain[0].Station[33].Status +
          this.getTrain[1].Station[33].Status +
          this.getTrain[2].Station[33].Status;
        return this.returnStyle(status);
      } else {
        let status =
          this.getTrain[0].Station[num].Status +
          this.getTrain[1].Station[num].Status +
          this.getTrain[2].Station[num].Status;
        return this.returnStyle(status);
      }
    },
    cheackStatusSmall(data) {
      let start = data.slice(0, 1);
      let num = data.slice(1);
      if (start == 1) {
        let status =
          this.getTrain[0].Station[num].Status +
          this.getTrain[1].Station[num].Status +
          this.getTrain[2].Station[num].Status;
        return this.returnStyleSmall(status);
      } else {
        let status =
          this.getTrain[0].Station[num].Status +
          this.getTrain[1].Station[num].Status +
          this.getTrain[2].Station[num].Status;
        return this.returnStyleSmall(status);
      }
    },
    returnStyleSmall(data) {
      switch (data) {
        case 0:
          return "st9";
        case 1:
          return "st12";
        case 2:
          return "st13";
      }
    },
    returnStyle(data) {
      switch (data) {
        case 0:
          return "st1";
        case 1:
          return "st2";
        case 2:
          return "st3";
      }
    },
  },
};
</script>
<style type="text/css">
.st0 {
  fill: #ffffff;
  stroke-width: 3;
}
.st1 {
  stroke: #32a852;
}
.st2 {
  stroke: #fded06;
}
.st3 {
  stroke: #d40824;
}
.st4 {
  font-family: "Teko";
  fill: #ffffff;
  font-size: 20px;
}
.st5 {
  cursor: pointer;
}
.st6 {
  letter-spacing: 2px;
  font-size: 3vh;
}
.st7 {
  font-family: "Teko";
  fill: #ffffff;
}
.st8 {
  display: block;
}
.st9 {
  display: none;
}
.st12 {
  fill: #fded06;
}
.st13 {
  fill: #d40824;
}
</style>
