import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "trains/";
import authHeader from "../services/auth-header";
export const operation = {
  state: {
    operation: {},
  },
  getters: {
    operation: (state) => state.operation,
  },
  mutations: {},
  actions: {
    getOper(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("setTrains", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setOperAuto(context, payload) {
      return axios
        .put(apiURL + "operation/auto/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("setTrains", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setOperManual(context, payload) {
      return axios
        .put(apiURL + "operation/manual/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("setTrains", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    MotorRevert(context, payload) {
      console.log(payload)
      return axios
        .put(apiURL + "motor/revert/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then((res) => {
          //console.log(res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    MotorForward(context, payload) {
      console.log(payload);
      return axios
        .put(apiURL + "motor/forward/" + payload._id, payload, {
          headers: authHeader(),
        })
        .then((res) => {
          //console.log(res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
